var $ = require('jquery');
window.jQuery = $;
window.$ = $;

$(function() {

	var sliding = false;
	var $triggers = $('.hp-slider li');
	var slide_count = $triggers.length;
	var current_slide = 1;

	// auto slide every 5 seconds
	var auto = setTimeout(function() {
		var next = (current_slide+1 <= slide_count) ? current_slide+1 : 1;
		changeSlide(next);
	}, 5000);

	// manual slide change
	$triggers.on('click', function() {
		var slide_id = $(this).attr('data-slide');
		changeSlide(slide_id);
	});

	function changeSlide(slide_id) {

		if (sliding) return;
		sliding = true;

		// reset active status on triggers
		$triggers.removeClass('active');

		// set new active status
		$('.hp-slider li[data-slide="' + slide_id + '"]').addClass('active');

		var $old_slide = $('.hp-slide:not(.d-none)');
		var $new_slide = $('.hp-slide[data-slide="' + slide_id + '"]');

		// transition slides
		$old_slide.fadeOut(200, function() {
			$old_slide.addClass('d-none');
			$new_slide.hide().removeClass('d-none').fadeIn(200);
			sliding = false;
		});

		// record the new slide
		current_slide = Number(slide_id);
		sliding = false;

		// queue the next auto slide
		clearTimeout(auto);
		auto = setTimeout(function() {
			var next = ((current_slide + 1) <= slide_count) ? (current_slide + 1) : 1;
			changeSlide(next);
		}, 5000);

	}

});
